import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { LOGIN } from "./config/routes/Routes";

import CssBaseline from "@mui/material/CssBaseline";
import { styled, useTheme } from "@mui/material/styles";

import Diversity3Icon from "@mui/icons-material/Diversity3";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import BackHandIcon from "@mui/icons-material/BackHand";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CreditCardIcon from '@mui/icons-material/CreditCard';

import Footer                             from "./components/Footer";
import DummyDashboard                     from "./components/DummyDashboard";
import ServiceManagementDashboard         from "./components/ServiceManagementDashboard";
import SigninigsAndIncidencesDashboard    from "./components/SigninigsAndIncidencesDashboard";
import ReportDashboard                    from "./components/ReportDashboard";
import UserManagerDashboard               from "./components/UserManagerDashboard";
import RoleManagerDashboard               from "./components/RoleManagerDashboard";
import MyProfile                          from "./components/MyProfile";
import CardManagementDashboard            from "./components/CardManagementDashboard";

import useAuth from "./hooks/useAuth";
import * as Privileges from "./config/roles/Privileges";

import { Container } from "@mui/system";
import {
  Typography,
  Toolbar,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  MenuItem,
  Menu,
  Avatar,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import axios from "axios";

const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  backgroundColor: "black",
}));

const drawerdata = [
  { name: "Dashboard", icon: <DashboardIcon/>, path: "/dashboard", privileges: null},
  { name: "Mi Perfil", icon: <PersonIcon />, path: "/profile", privileges: Privileges.BLQ_G_PER },
  { name: "Gestión de Fichajes", icon: <EventRepeatIcon />, path: "/incidents", privileges: [Privileges.G_INC_FICH, Privileges.G_INC_INC_OWN, Privileges.G_INC_INC_ALL] },
  { name: "Gestión de Usuarios", icon: <ManageAccountsIcon />, path:"/users", privileges: Privileges.BLQ_G_USU },
  { name: "Gestión de Departamentos", icon: <Diversity3Icon />, path: "/services", privileges: Privileges.BLQ_G_DEP },
  { name: "Gestión de Roles", icon: <BackHandIcon />, path:"/roles", privileges: Privileges.BLQ_G_ROL },
  { name: "Gestión de Reports", icon: <MoveToInboxIcon />, path:"/reports", privileges: Privileges.BLQ_G_REP },
  { name: "Gestión de tarjetas", icon: <CreditCardIcon />, path: "/cards", privileges: Privileges.G_COD}
];

function Base(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  let navigate = useNavigate();
  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;
  const userName = userInfo.name;
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState(false);

  const getList = () => (
    <Container
      sx={{ backgroundColor: "black", height: "100%" }}
      onClick={() => setOpen(false)}
    >
      {drawerdata.map(function (item, index) {
        if (
          item.privileges == null ||
          userPrivileges.some((privilege) =>
            item.privileges.includes(privilege)
          )
        ) {
          return (
            <Link key={index} to={item.path} relative="path">
              <ListItem>
                <ListItemIcon sx={{ color: "#FFFFFF" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} sx={{ color: "#FFFFFF" }} />
              </ListItem>
            </Link>
          );
        }
      })}
    </Container>
  );

  function logout() {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["access-token"];
    navigate(LOGIN);
  }

  function renderSwitch(seccion) {
    switch (seccion) {
      case "dashboard":
        return <DummyDashboard />;
      case "profile":
        return <MyProfile />;
      case "incidents":
        return <SigninigsAndIncidencesDashboard />;
      case "services":
        return <ServiceManagementDashboard />;
      case "reports":
        return <ReportDashboard/>;
      case "users":
        return <UserManagerDashboard />;
      case "roles":
        return <RoleManagerDashboard />;
      case "cards":
        return <CardManagementDashboard />;  
      default:
        return null;
    }
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "#F2F2F2",
      },
      children: (
        <Typography
          sx={{ fontFamily: "Rubik", fontWeight: "600", color: "#05000D" }}
        >
          {name.split(" ")[0][0]}
          {name.split(" ")[1][0]}
        </Typography>
      ),
    };
  }

  const seccion = props.seccion;

  return (
    <Container>
      <CssBaseline />
      <Drawer open={open} anchor={"left"} onClose={() => setOpen(false)}>
        <DrawerHeader>
          <IconButton onClick={() => setOpen(false)}>
            {useTheme().direction === "ltr" ? (
              <ChevronLeftIcon sx={{ color: "#FFFFFF" }} />
            ) : (
              <ChevronRightIcon sx={{ color: "#FFFFFF" }} />
            )}
          </IconButton>
        </DrawerHeader>
        {getList()}
      </Drawer>

      <AppBar
        position="fixed"
        open={open}
        sx={{ borderRadius: "0px 0px 10px 10px", backgroundColor: "black" }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setOpen(true)}
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{ fontFamily: "Rubik", flexGrow: 1, fontWeight: "600" }}
          >
            Kairos
          </Typography>

          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar {...stringAvatar(userName)} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 26,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={logout}>
                <Typography
                  sx={{ fontFamily: "Rubik" }}
                >
                  Cerrar Sesión
                </Typography>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      {renderSwitch(seccion)}

      <Footer />
    </Container>
  );
}

export default Base;
