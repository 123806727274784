import { Container, Typography, Button, Grid } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  CALL_USER_DATA,
  CALL_REPORT,
  CALL_USERS,
  CALL_SERVICES,
  CALL_REPORT_SIGNINGS,
} from "../config/routes/Routes";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useAuth from "../hooks/useAuth";
import * as Privileges from "../config/roles/Privileges";

function ReportManagerDashboard({ setFilteredRows, setFilteredTabActive, changeActiveTab }) {
  const userInfo = useAuth();
  const userPrivileges = userInfo.privileges;
  const isCreateEnabled = userPrivileges.includes(Privileges.G_REP_CREATE);
  const canSeeSignings = userPrivileges.includes(Privileges.G_REP_FICH);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedFormat, setSelectedFormat] = useState(null);
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [optionsUser, setOptionsUser] = useState([]);
  const [optionsService, setOptionsService] = useState([]);
  const optionsFormat = [
    { id: 1, name: "PDF" },
    { id: 2, name: "Excel" },
  ];
  if (canSeeSignings)
    optionsFormat.push({ id: 3, name: "Vista en plataforma" });

  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    user: null,
    service: null,
    format: null,
  });

  useEffect(() => {
    getOptionsUsers();
    getOptionsServices();
    getUserData();
  }, []);

  function getOptionsUsers() {
    axios
      .get(CALL_USERS)
      .then((response) => {
        setOptionsUser(
          response.data.map((user) => ({
            id: user.id,
            name: user.name,
          }))
        );
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  function getOptionsServices() {
    axios
      .get(CALL_SERVICES)
      .then((response) => {
        setOptionsService(
          response.data.map((service) => ({
            id: service.id,
            name: service.name,
          }))
        );
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  function getUserData() {
    axios
      .get(CALL_USER_DATA)
      .then((response) => {
        if (!formData.user) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            user: { id: response.data.id, name: response.data.name },
          }));
          setSelectedUser(response.data);
        }
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  function getFilteredSignings(filterParams) {
    axios
      .get(CALL_REPORT_SIGNINGS, { params: filterParams })
      .then((response) => {
        setFilteredRows(response.data);
        setFilteredTabActive(true);
        changeActiveTab(1);
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  const handleOptionChangeUser = (e, newValue) => {
    setSelectedUser(newValue);
    setFormData((prevFormData) => ({
      ...prevFormData,
      user: newValue,
    }));
  };

  const handleOptionChangeService = (e, newValue) => {
    setSelectedService(newValue);
    setFormData((prevFormData) => ({
      ...prevFormData,
      service: newValue,
    }));
  };

  const handleOptionChangeFormat = (e, newValue) => {
    setInvalidFormat(newValue == null);
    setSelectedFormat(newValue);
    setFormData((prevFormData) => ({
      ...prevFormData,
      format: newValue,
    }));
  };

  const handleInitialDate = (newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: newValue,
    }));
  };

  const handleFinalDate = (newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: newValue,
    }));
  };

  function formatDataForMySQL(date) {
    let day = String(date.date()).padStart(2, "0");
    let month = String(date.month() + 1).padStart(2, "0");
    let year = date.year();
    return `${year}-${month}-${day}`;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!formData.hasOwnProperty("format") || formData.format == null) {
      setInvalidFormat(true);
      return;
    }

    const sendFormData = {
      startDate: formData.startDate ? formatDataForMySQL(formData.startDate) : formData.startDate,
      endDate: formData.endDate ? formatDataForMySQL(formData.endDate) : formData.endDate,
      user: formData.user,
      service: formData.service,
      format: formData.format,
    };

    sendFormData.format.id === 3 ? getFilteredSignings(sendFormData) : handleRequest(sendFormData);
  }

  function handleSubmitWeek(e) {
    e.preventDefault();
    if (!formData.hasOwnProperty("format") || formData.format == null) {
      setInvalidFormat(true);
      return;
    }

    let formWeek;
    let today = dayjs();
    let oneWeekAgo = dayjs().subtract(1, "week");
    formWeek = {
      startDate: formatDataForMySQL(oneWeekAgo),
      endDate: formatDataForMySQL(today),
      user: formData.user,
      service: formData.service,
      format: formData.format,
    };

    formWeek.format.id === 3 ? getFilteredSignings(formWeek) : handleRequest(formWeek);
  }

  function handleSubmitMonth(e) {
    e.preventDefault();
    if (!formData.hasOwnProperty("format") || formData.format == null) {
      setInvalidFormat(true);
      return;
    }

    let formMonth;
    let today = dayjs();
    let oneMonthAgo = dayjs().subtract(1, "month");

    formMonth = {
      startDate: formatDataForMySQL(oneMonthAgo),
      endDate: formatDataForMySQL(today),
      user: formData.user,
      service: formData.service,
      format: formData.format,
    };

    formMonth.format.id === 3 ? getFilteredSignings(formMonth) : handleRequest(formMonth);
  }

  function handleRequest(params) {
    axios
      .post(CALL_REPORT, params, { responseType: "blob" })
      .then((response) => {
        let formato = params.format.name;
        let blob = new Blob(
          [response.data],
          formato === "PDF"
            ? { type: "application/pdf" }
            : {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              }
        );
        let url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = url;
        link.download = formato === "PDF" ? "report.pdf" : "report.xlsx";
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        error.handleGlobally && error.handleGlobally();
      });
  }

  return (
    <Container sx={{ height: "70vh" }}>
      {isCreateEnabled && (
        <Grid container spacing={2} sx={{ marginBottom: "2rem" }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InputLabel htmlFor="datepicker1">Fecha Inicial</InputLabel>
              <DatePicker
                id="datepicker1"
                onChange={handleInitialDate}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <InputLabel htmlFor="datepicker2">Fecha Final</InputLabel>
              <DatePicker
                id="datepicker2"
                minDate={formData.startDate}
                onChange={handleFinalDate}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={12}>
            <Autocomplete
              disablePortal
              id="combo-box"
              options={optionsUser}
              value={selectedUser}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleOptionChangeUser}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Nombre" />}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Autocomplete
              disablePortal
              id="combo-box"
              options={optionsService}
              value={selectedService}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleOptionChangeService}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Servicio" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Autocomplete
              disablePortal
              id="combo-box"
              options={optionsFormat}
              value={selectedFormat}
              onChange={handleOptionChangeFormat}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Formato de Fichero"
                  error={invalidFormat}
                />
              )}
            />
            <Grid item xs={12}>
              <Typography
                variant="caption"
                className="font-black text-red-800"
                hidden={invalidFormat ? "" : "hidden"}
              >
                Selecciona un formato de fichero
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ width: "100%" }}
            >
              <Typography sx={{ fontWeight: "600" }}>Crear Report</Typography>
            </Button>
          </Grid>
        </Grid>
      )}
      <Typography
        variant="h5"
        sx={{ fontFamily: "Rubik", fontWeight: "600", paddingBottom: "1rem" }}
      >
        Variantes predefinidas
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            onClick={handleSubmitWeek}
            sx={{ width: "100%" }}
          >
            <Typography sx={{ fontWeight: "600" }}>Report Semanal</Typography>
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            onClick={handleSubmitMonth}
            sx={{ width: "100%" }}
          >
            <Typography sx={{ fontWeight: "600" }}>Report Mensual</Typography>
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ReportManagerDashboard;
